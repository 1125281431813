import { faSpotify } from "@fortawesome/free-brands-svg-icons/faSpotify";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { faHeadphones } from "@fortawesome/free-solid-svg-icons/faHeadphones";
import { faShareSquare } from "@fortawesome/free-solid-svg-icons/faShareSquare";
import { faBookmark } from "@fortawesome/pro-regular-svg-icons/faBookmark";
import { faEar } from "@fortawesome/pro-regular-svg-icons/faEar";
import { faBookmark as faBookmarked } from "@fortawesome/pro-solid-svg-icons/faBookmark";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";
import { setBookmarkedPromise } from "routines/bookmarks";
import { setListenedPromise } from "routines/listens";

import ButtonWithPopout from "components/Buttons/ButtonWithPopout";
import FlatButtonWithIcon from "components/Buttons/FlatButtonWithIcon";
import PlayButton from "components/Buttons/PlayButton";
import { HashLink } from "components/Common/HashLink";
import EpisodeExternalPlayerLinks from "components/Podcast/Episode/EpisodeExternalPlayerLinksAsync";
import EpisodeSharingLinks from "components/Podcast/Episode/EpisodeSharingLinksAsync";
import PodcastExternalPlayerLinks from "components/Podcast/PodcastExternalPlayerLinksAsync";
import PodcastSharingLinks from "components/Podcast/PodcastSharingLinksAsync";

import useUserHasPro from "../../hooks/useUserHasPro";
import getPodcastUrl from "../../utils/entity/getPodcastUrl";
import accessibleClickProps from "../../utils/misc/accessibleClickProps";

import authActions from "actions/auth";
import modalActions from "actions/modals";
import { selectIsSavedToUserList } from "selectors/podcast";
import {
  episodeExclusiveTo,
  podcastExclusiveTo,
} from "utils/entity/exclusiveTo";
import { capitalize } from "utils/misc";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useActivityContext from "hooks/useActivityContext";
import { useLoggedIn } from "hooks/useLoggedInUser";
import useReduxState from "hooks/useReduxState";
import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import faAddToList from "styles/icons/faAddToList";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  podcastButtons: {
    display: "flex",
    flexDirection: "row",
    marginTop: "1rem",

    [ScreenSizes.lgAndAbove]: {
      marginTop: ".7rem",
    },
  },
  buttonContainer: {
    display: "flex",
    flex: 1,
  },
  buttonInner: {
    ...gStyles.noButton,
    display: "flex",
    flex: 1,
    WebkitAppearance: "none",
  },
  listenOnContainer: {
    minWidth: "18rem",
    background: "#fff",
    maxWidth: "28rem",
  },
  listenOnTitle: {
    color: colours.oldSecondary,
    ...gStyles.fontSemiBold,
    fontSize: "0.75em",
    marginBottom: "1rem",
    textAlign: "center",
    borderBottom: "1px solid #f1f1f1",
    textTransform: "uppercase",
    lineHeight: "3em",
  },
};

const buttonPopoverStyles = {
  container: {
    display: "flex",
    flex: 1,
  },
  contentWrapper: {
    flex: 1,
  },
  content: {
    display: "flex",
    flex: 1,
  },
  input: {
    display: "flex",
    flex: 1,
  },
};

const saveButtonStyles = {
  label: {
    color: colours.actioned,
  },
  labelText: {
    color: colours.actioned,
  },
};

const PodcastButtons = (props) => {
  const { entity_type, podcast, entity } = props;
  const { styles } = useStyles(baseStyles, props);
  const activityContext = useActivityContext();

  const userHasPro = useUserHasPro();

  const spotifyExclusive =
    entity_type === "podcast"
      ? podcastExclusiveTo(entity, "spotify")
      : episodeExclusiveTo(entity, "spotify");

  const { setBookmarked } = useRoutinePromises({
    setBookmarked: setBookmarkedPromise,
  });

  const savedInUserList = useReduxState(
    (state) => selectIsSavedToUserList(state, entity.get("id"), entity_type),
    [entity, entity_type]
  );

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const isLoggedIn = useLoggedIn();
  const { startAuthFlow } = useActionCreators({
    startAuthFlow: authActions.startAuthFlow,
  });
  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const handleListenOnAppsToggle = useCallback(
    (opened) => {
      if (opened) {
        sendGAEvent({
          action: "externalPlayerModalOpened",
          entity_id: entity.get("id"),
          entity_type,
          entity_name: entity.get("title"),
          context: "PodcastButtons",
          spotifyExclusive,
        });
      }
    },
    [entity, entity_type, spotifyExclusive]
  );

  const openExclusiveModal = useCallback(() => {
    handleListenOnAppsToggle(true);
    showModal("openExclusive", {
      entity,
      entityType: entity_type,
      podcast,
    });
  }, [showModal, handleListenOnAppsToggle, entity_type, entity, podcast]);

  const handleBookmarkClick = useCallback(
    (bookmark) => () =>
      setBookmarked({
        entity,
        entity_type,
        entity_id: entity && entity.get("id"),
        bookmark,
      }),
    [setBookmarked, entity_type, entity]
  );

  const isListened = entity.getIn(["user_data", "listened"]);

  const iconSize = useMemo(() => {
    if (mobile) {
      return "1.7rem";
    }

    return "1.4rem";
  }, [mobile]);

  const { setListened } = useRoutinePromises({
    setListened: setListenedPromise,
  });

  const handleListenToggle = useCallback(() => {
    setListened({
      listened: !isListened,
      episode_id: entity.get("id"),
      entity_id: entity.get("id"),
      entity_type,
      entity,
      analyticsVariables: {
        componentContext: "PodcastButtons",
      },
    });
  }, [entity, entity_type, isListened, setListened]);

  const renderListenOnContent = useCallback(
    ({ toggleMenu }) => (
      <div className={css(styles.listenOnContainer)}>
        <div className={css(styles.listenOnTitle)}>
          {entity_type === "podcast"
            ? "Play podcast with"
            : "Play episode with"}
        </div>
        {entity_type === "podcast" ? (
          <PodcastExternalPlayerLinks
            podcast={entity}
            {...accessibleClickProps(toggleMenu)}
          />
        ) : (
          <EpisodeExternalPlayerLinks
            podcast={podcast}
            episode={entity}
            {...accessibleClickProps(toggleMenu)}
          />
        )}
      </div>
    ),
    [
      styles.listenOnContainer,
      styles.listenOnTitle,
      entity_type,
      entity,
      podcast,
    ]
  );

  const renderListenOnButton = useCallback(
    ({ ref, toggleMenu }) => (
      <div ref={ref} className={css(styles.buttonInner)}>
        <FlatButtonWithIcon
          icon={spotifyExclusive ? faSpotify : faHeadphones}
          iconSize={iconSize}
          title={
            spotifyExclusive
              ? "This podcast is exclusive and may only be playable with a third party subscription."
              : "Open In External App"
          }
          label={spotifyExclusive ? "Exclusive" : "Apps"}
          {...accessibleClickProps(
            spotifyExclusive ? openExclusiveModal : toggleMenu
          )}
        />
      </div>
    ),
    [iconSize, spotifyExclusive, openExclusiveModal, styles.buttonInner]
  );

  const renderSaveButton = useCallback(
    (buttonProps) => (
      <div className={css(styles.buttonInner)} {...buttonProps}>
        <FlatButtonWithIcon
          icon={savedInUserList ? faAddToList : faAddToList}
          label={savedInUserList ? "Added" : "List"}
          iconSize={iconSize}
          styles={savedInUserList ? saveButtonStyles : undefined}
        />
      </div>
    ),
    [styles.buttonInner, savedInUserList, iconSize]
  );

  const handleContactButtonClick = useCallback(() => {
    sendGAEvent({
      action: "clickContactPodcastButton",
      context: "SpreadTheWord",
      podcast_id: podcast?.get("id"),
      podcast_title: podcast?.get("title"),
      userHasPro,
    });
  }, [podcast, userHasPro]);

  const handleAddEntity = useCallback(() => {
    if (isLoggedIn) {
      showModal("prompt", {
        promptType: "list",
        entity,
        entity_type,
        page: `${entity_type} view`,
      });
      // TODO

      // sendGAEvent({
      //  action: `${entity_type}AboutPage-AddListClicked`,
      //  [`${entity_type}_id`]: entity.get("id"),
      // });
    } else {
      startAuthFlow(undefined, {
        entity,
        entity_type: "userlist",
        entity_type_name: "list",
        entityAction: "action",
        analyticsVariables: {
          triggered_by: "addPodcastToListButton",
        },
      });
    }
  }, [isLoggedIn, showModal, startAuthFlow, entity, entity_type]);

  const handleToggleShare = useCallback(
    (toggleMenu, open) => () => {
      if (!open) {
        sendGAEvent({
          entity_type,
          entity_id: entity?.get("id"),
          entity_name: entity?.get("title") || entity?.get("name"),
          componentContext: "PodcastButtons",
          context: "main share button",
          ...activityContext,
          action: "shareModalOpen",
        });
      }

      toggleMenu();
    },
    [activityContext, entity_type, entity]
  );

  const renderAddToListButton = () => (
    <div {...accessibleClickProps(handleAddEntity)} style={{ width: "100%" }}>
      {renderSaveButton()}
    </div>
  );

  const renderBookmarkButton = useCallback(
    (buttonProps) => {
      const bookmarked = entity.getIn(["user_data", "bookmarked"]);
      const bookmarkSaving = entity.getIn(["user_data", "bookmark_saving"]);

      const instantFeedback = false;
      let displayAsSaved = bookmarkSaving ? !bookmarked : bookmarked;

      if (instantFeedback) {
        displayAsSaved = bookmarked;
      }

      let label = displayAsSaved ? "Saved" : "Bookmark";
      let icon = displayAsSaved ? faBookmarked : faBookmark;
      let title = bookmarked
        ? `${capitalize(entity_type)} Bookmarked`
        : `Bookmark ${capitalize(entity_type)}`;

      if (bookmarkSaving) {
        icon = faCircleNotch;
        label = "Saving";
        title = `Bookmarking ${capitalize(entity_type)}`;
      }

      const bookmarkOnClick = !displayAsSaved; // This is so the action matches what the user sees, not hte actual status of the save

      return (
        <div className={css(styles.buttonInner)} {...buttonProps}>
          <FlatButtonWithIcon
            {...accessibleClickProps(
              bookmarkSaving ? null : handleBookmarkClick(bookmarkOnClick)
            )}
            icon={icon}
            label={label}
            title={title}
            iconSize={iconSize}
            styles={bookmarked ? saveButtonStyles : undefined}
            iconSpin={!instantFeedback && bookmarkSaving}
          />
        </div>
      );
    },
    [entity, styles.buttonInner, handleBookmarkClick, entity_type, iconSize]
  );

  const renderShareButton = useCallback(
    ({ ref, toggleMenu, open }) => (
      <div ref={ref} className={css(styles.buttonInner)}>
        <FlatButtonWithIcon
          icon={faShareSquare}
          iconSize={iconSize}
          label="Share"
          {...accessibleClickProps(handleToggleShare(toggleMenu, open))}
        />
      </div>
    ),
    [iconSize, handleToggleShare, styles.buttonInner]
  );

  const renderShareContent = useCallback(
    (downshiftProps) => {
      if (entity_type === "podcast") {
        return (
          <PodcastSharingLinks
            downshiftProps={downshiftProps}
            podcast={entity}
          />
        );
      }

      return (
        <EpisodeSharingLinks
          downshiftProps={downshiftProps}
          episode={entity}
          podcast={podcast}
        />
      );
    },
    [entity_type, entity, podcast]
  );

  const renderListenButton = useCallback(
    (buttonProps) => (
      <div className={css(styles.buttonInner)} {...buttonProps}>
        <FlatButtonWithIcon
          icon={faEar}
          label="Listened"
          iconSize={iconSize}
          styles={isListened ? saveButtonStyles : undefined}
          {...accessibleClickProps(handleListenToggle)}
        />
      </div>
    ),
    [handleListenToggle, iconSize, isListened, styles.buttonInner]
  );

  const SLIDING_PROPS = {
    fullWidth: true,
    fullHeight: true,
    showOverlay: true,
    styles: {
      childrenInner: {
        height: "auto",
        backgroundColor: colours.white,
        position: "absolute",
        bottom: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  };

  return (
    <div className={css(styles.podcastButtons)}>
      {entity_type === "podcast" && (
        <div className={css(styles.buttonContainer)}>
          <div className={css(styles.buttonInner)}>
            <PlayButton
              entity_type={entity_type}
              entity_id={entity.get("id")}
              render={(playProps) => {
                const buttonProps = { ...playProps };

                delete buttonProps.isPlaying;

                return (
                  <FlatButtonWithIcon {...buttonProps} iconSize={iconSize} />
                );
              }}
            />
          </div>
        </div>
      )}
      <div className={css(styles.buttonContainer)}>
        <ButtonWithPopout
          styles={buttonPopoverStyles}
          renderButton={renderListenOnButton}
          renderContent={renderListenOnContent}
          onButtonToggle={handleListenOnAppsToggle}
          placement="bottom"
          ariaLabel="Show Listen On dropdown"
          slidingProps={SLIDING_PROPS}
          slideInFrom={isWindowSizeOrLess("medium") ? "bottom" : null}
          hideArrow={isWindowSizeOrLess("medium")}
        />
      </div>

      {entity_type === "episode" && (
        <div className={css(styles.buttonContainer)}>
          {renderListenButton()}
        </div>
      )}
      <div className={css(styles.buttonContainer)}>
        {renderAddToListButton()}
      </div>
      {(entity_type === "episode" || entity_type === "podcast") && (
        <div className={css(styles.buttonContainer)}>
          {renderBookmarkButton()}
        </div>
      )}
      {mobile && (
        <div className={css(styles.buttonContainer)}>
          <FlatButtonWithIcon
            icon={faEnvelope}
            label={"Contact"}
            iconSize={iconSize}
            to={getPodcastUrl(
              entity_type === "podcast" ? entity : podcast,
              "/insights#podcast-contacts"
            )}
            outerComponent={HashLink}
            {...accessibleClickProps(handleContactButtonClick, {
              preventDefault: false,
            })}
          />
        </div>
      )}
      <div className={css(styles.buttonContainer)}>
        <ButtonWithPopout
          styles={buttonPopoverStyles}
          renderButton={renderShareButton}
          renderContent={renderShareContent}
          placement="bottom"
          positionFixed={mobile}
          ariaLabel="Show Share dropdown"
        />
      </div>
    </div>
  );
};

PodcastButtons.propTypes = {
  entity_type: PropTypes.string.isRequired,
  entity: PropTypes.instanceOf(Map).isRequired,
  podcast: PropTypes.instanceOf(Map),
};
PodcastButtons.defaultProps = {
  podcast: null,
};

export default memo(PodcastButtons);
